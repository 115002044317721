<template>
	<div
		id="kt_aside_menu_wrapper"
		ref="scrollElRef"
		class="hover-scroll-overlay-y my-5 my-lg-5"
		data-kt-scroll="true"
		data-kt-scroll-activate="{default: false, lg: true}"
		data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
		data-kt-scroll-height="auto"
		data-kt-scroll-offset="0"
		data-kt-scroll-wrappers="#kt_aside_menu"
	>
		<!--begin::Menu-->
		<div id="#kt_header_menu" class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
			<div class="accordion" id="accordionExample">
				<div class="accordion-item" v-if="$can('view_dashboard')">
					<h2 class="accordion-header">
						<div class="accordion-item" v-if="$can('view_catalog')">
							<h2 class="accordion-header" id="headingOne">
								<router-link v-on:click="clearSearchHistory" to="/dashboard">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseDash"
										aria-expanded="false"
										aria-controls="collapseDash"
									>
										<span class="menu-icon">
											<i class="bi fs-2 bi-app-indicator"></i>
										</span>
										<span class="menu-title"> {{ $t("message.DASHBOARD") }}</span>
									</button>
								</router-link>
							</h2>
							<div id="collapseDash" class="accordion-collapse collapse" aria-labelledby="headingDash" data-bs-parent="#accordionExample"></div>
						</div>
					</h2>
				</div>
				<div class="accordion-item" v-if="$can('view_catalog')">
					<h2 class="accordion-header" id="headingOne">
						<button
							ref="collapseableOpener"
							class="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
							aria-expanded="false"
							aria-controls="collapseOne"
						>
							<span class="menu-icon">
								<i class="bi fs-2 bi-shop"></i>
							</span>
							<span class="menu-title">{{ $t("message.CATALOG") }}</span>
							<span class="arrow-icon">
								<i class="fas fa-chevron-right"></i>
							</span>
						</button>
					</h2>
					<div id="collapseOne" ref="collapseableArea" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
						<div class="list-group">
							<router-link v-if="$can('view_category_list')" class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/catalog/categories">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.CATEGORIES") }}</span>
							</router-link>
							<router-link v-if="$can('view_products_list')" class="list-group-item list-group-item-action" v-on:click="clearSearchHistory" to="/catalog/products">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.PRODUCTS") }}</span>
							</router-link>
							<router-link v-if="$can('view_manufacturers_list')" class="list-group-item list-group-item-action" v-on:click="clearSearchHistory" to="/catalog/manufacturers">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.MANUFACTURERS") }}</span>
							</router-link>
							<router-link v-if="$can('view_attributes_list')" class="list-group-item list-group-item-action" v-on:click="clearSearchHistory" to="/catalog/attributes">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.ATTRIBUTES") }}</span>
							</router-link>
							<router-link v-if="$can('view_dimensions_list')" class="list-group-item list-group-item-action" v-on:click="clearSearchHistory" to="/catalog/dimensions">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.DIMENSIONS") }}</span>
							</router-link>
						</div>
					</div>
				</div>
				<div class="accordion-item" v-if="$can('view_customer')">
					<h2 class="accordion-header" id="headingTwo">
						<button
							ref="collapseableOpener2"
							class="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseTwo"
							aria-expanded="false"
							aria-controls="collapseTwo"
						>
							<span class="menu-icon">
								<i class="fs-2 bi-people"></i>
							</span>
							<span class="menu-title">{{ $t("message.CUSTOMERS") }}</span>
							<span class="arrow-icon">
								<i class="fas fa-chevron-right"></i>
							</span>
						</button>
					</h2>
					<div id="collapseTwo" ref="collapseableArea2" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
						<div class="list-group">
							<router-link v-if="$can('view_customers_list')" class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/customer/lists">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.CUSTOMERS") }}</span>
							</router-link>
							<router-link v-if="$can('view_price_groups_list')" class="list-group-item list-group-item-action" v-on:click="clearSearchHistory" to="/customer/price-groups">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.PRICE_GROUPS") }}</span>
							</router-link>
							<router-link v-if="$can('view_customer_groups_list')" class="list-group-item list-group-item-action" v-on:click="clearSearchHistory" to="/customer/customer-groups">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.CUSTOMER_GROUPS") }}</span>
							</router-link>
						</div>
					</div>
				</div>
				<div class="accordion-item" v-if="$can('view_order_list')">
					<h2 class="accordion-header" id="headingThree">
						<button
							ref="collapseableOpener3"
							class="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseThree"
							aria-expanded="false"
							aria-controls="collapseThree"
						>
							<span class="menu-icon">
								<i class="bi bi-bag-check fs-2"></i>
							</span>
							<span class="menu-title">{{ $t("message.ORDERS") }}</span>
							<span class="arrow-icon">
								<i class="fas fa-chevron-right"></i>
							</span>
						</button>
					</h2>
					<div id="collapseThree" ref="collapseableArea3" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
						<div class="list-group">
							<router-link class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/orders">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.ORDERS") }}</span>
							</router-link>
							<router-link class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/subscription">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.SUBSCRIPTIONS_ORDERS") }}</span>
							</router-link>
						</div>
					</div>
				</div>
				<div class="accordion-item" v-if="$can('view_system')">
					<h2 class="accordion-header" id="headingFour">
						<button
							ref="collapseableOpener4"
							class="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFour"
							aria-expanded="false"
							aria-controls="collapseFour"
						>
							<span class="menu-icon">
								<i class="fs-2 bi bi-gear"></i>
							</span>
							<span class="menu-title">{{ $t("message.SYSTEM") }}</span>
							<span class="arrow-icon">
								<i class="fas fa-chevron-right"></i>
							</span>
						</button>
					</h2>
					<div id="collapseFour" ref="collapseableArea4" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
						<div class="list-group">
							<router-link v-if="$can('view_users_list')" class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/system/users">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.USERS") }}</span>
							</router-link>
							<router-link v-if="$can('view_setting')" class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/system/settings">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.SETTINGS") }}</span>
							</router-link>
							<router-link style="width:max-content" class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/permission/groups">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.PERMISSIONS_GROUP") }}</span>
							</router-link>
							<router-link class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/economic">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.ECONOMIC") }}</span>
							</router-link>
							<router-link class="list-group-item list-group-item-action" aria-current="true" v-on:click="clearSearchHistory" to="/jobs-monitor">
								<span class="menu-bullet">
									<span class="bullet bullet-dot"></span>
								</span>
								<span class="menu-title"> {{ $t("message.JOBS_MONITOR") }}</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setPageHistory } from "@/core/helpers/toolbar";
import { useStore } from "vuex";
import { ref, onMounted, watchEffect } from "vue";

export default {
	name: "AsideMenu",
	props: {
		hideCollapseableArea: Boolean,
	},
	setup(props, context) {
		const store = useStore();
		const collapseableArea = ref({});
		const collapseableOpener = ref({});
		const collapseableArea2 = ref({});
		const collapseableOpener2 = ref({});
		const collapseableArea3 = ref({});
		const collapseableOpener3 = ref({});
		const collapseableArea4 = ref({});
		const collapseableOpener4 = ref({});

		watchEffect(() => {
			if (props.hideCollapseableArea == true) {
				if (collapseableArea.value.classList.contains("show")) {
					collapseableArea.value.classList.remove("show");
				}
				if (collapseableArea2.value.classList.contains("show")) {
					collapseableArea2.value.classList.remove("show");
				}
				if (collapseableArea3.value.classList.contains("show")) {
					collapseableArea3.value.classList.remove("show");
				}
				if (collapseableArea4.value.classList.contains("show")) {
					collapseableArea4.value.classList.remove("show");
				}
				collapseableOpener.value.classList.add("collapsed");
				collapseableOpener2.value.classList.add("collapsed");
				collapseableOpener3.value.classList.add("collapsed");
				collapseableOpener4.value.classList.add("collapsed");
				context.emit("valueChanged", false);
			}
		});

		const clearSearchHistory = () => {
			setPageHistory({});
			store.commit("SET_SEARCH_DATA", {});
		};

		return {
			clearSearchHistory,
			collapseableArea,
			collapseableOpener,
			collapseableArea2,
			collapseableOpener2,
			collapseableArea3,
			collapseableOpener3,
			collapseableArea4,
			collapseableOpener4,
		};
	},
};
</script>
